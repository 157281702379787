import {CheckoutLegacyMutationsCreateCartArgs, VolatileCartResponse} from '@wix/wixstores-graphql-schema-node';
import {GRAPHQL_SOURCE, graphqlOperation} from '../constants';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {query as createCartQuery} from '../graphql/mutations/createCart.graphql';
import {GRAPHQL_NODE_ENDPOINT} from '@wix/wixstores-client-core/dist/es/src/dashboard/topology';

export function gqlNodeQuery(siteStore: SiteStore, query: string, variables: {}, operationName: string) {
  return siteStore.httpClient.post(siteStore.resolveAbsoluteUrl(`${GRAPHQL_NODE_ENDPOINT}`), {
    query,
    variables,
    source: GRAPHQL_SOURCE,
    operationName,
  });
}

export class CheckoutApiService {
  constructor(private readonly siteStore: SiteStore) {
    //
  }

  public async createCart(
    createCartParams: CheckoutLegacyMutationsCreateCartArgs,
    shouldHandleCartCreateErrors: boolean
  ): Promise<VolatileCartResponse> {
    const creatCartResponse: any = await gqlNodeQuery(
      this.siteStore,
      createCartQuery,
      createCartParams,
      graphqlOperation.CreateCart
    );

    if (shouldHandleCartCreateErrors && creatCartResponse.errors) {
      throw new Error("Couldn't go to checkout due to volatileCartService error");
    }
    return creatCartResponse.data.checkout.createCart;
  }
}
