import {gqlQuery} from './getProduct';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {query as getCartServiceQuery} from '../graphql/getCartService.graphql';
import {graphqlOperation} from '../constants';
import {GetCartServiceQuery} from '../graphql/queries-schema';
import {CheckoutApiService} from './checkout-api-service';
import {CheckoutType} from './DirectPurchaseService';
import {IProductDTO, StandaloneCheckoutIds, UserInput, VolatileCartFromServer} from '../types/app-types';
import {CheckoutLegacyMutationsCreateCartArgs} from '@wix/wixstores-graphql-schema-node';
import {ProductService} from './ProductService';
import {StoreMetaDataService} from './StoreMetaDataService';
import {SPECS} from '../specs';

export const VolatileCartApiUrl = '/stores/v1/carts/volatileCart';

export class VolatileCartService {
  private volatileCart: VolatileCartFromServer['cart'] & {checkoutId?: string};
  private readonly checkoutApiService: CheckoutApiService;

  constructor(
    private readonly siteStore: SiteStore,
    private readonly storeMetaDataService: StoreMetaDataService,
    private readonly reportError: (e) => any
  ) {
    this.checkoutApiService = new CheckoutApiService(siteStore);
  }

  public getStandaloneCheckoutIds = async (
    product: IProductDTO,
    userInputs: UserInput,
    checkoutType: CheckoutType,
    isPreOrderState: boolean
  ): Promise<StandaloneCheckoutIds> => {
    const storeMetaData = await this.storeMetaDataService.fetchStoreInfo();
    const customTextFieldSelection = ProductService.mapCustomTextFields(product, userInputs);
    const params: CheckoutLegacyMutationsCreateCartArgs = {
      productId: ProductService.getProductId(product),
      optionSelectionId: ProductService.mapOptionSelectionIds(userInputs),
      customTextFieldSelection,
      quantity: ProductService.getQuantity(userInputs),
      subscriptionOptionId: ProductService.getSubscriptionOptionId(userInputs),
      variantId: ProductService.getSelectedVariantId(product, userInputs),
      options: ProductService.mapUserInputsToSelectedOptions(product, userInputs),
      isPickupOnly: storeMetaData.shipping.isPickupOnly,
    };
    const shouldHandleCartCreateErrors = this.siteStore.experiments.enabled(
      SPECS.PRODUCT_PAGE_NAVIGATE_TO_HOME_PAGE_UPON_BUY_NOW_CART_CREATE_ERROR
    );

    if (isPreOrderState) {
      params.preOrderRequested = true;
    }

    await this.checkoutApiService
      .createCart(params, shouldHandleCartCreateErrors)
      .then((res) => {
        this.volatileCart = res;
      })
      .catch(() => {
        try {
          if (shouldHandleCartCreateErrors) {
            this.reportError(new Error("Couldn't go to checkout due to volatileCartService error"));
          }
        } catch {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          void this.siteStore.getHomepageLink().then((homepage) => {
            this.siteStore.navigateToLink(homepage);
          });
        }
      });

    return {cartId: this.cartId, checkoutId: this.checkoutId};
  };

  public getCart = async (): Promise<GetCartServiceQuery> => {
    return (
      await gqlQuery(
        this.siteStore,
        getCartServiceQuery,
        {cartId: this.cartId, locale: this.siteStore.locale, checkoutId: this.checkoutId},
        graphqlOperation.GetCartService
      )
    ).data;
  };

  public resetCart() {
    this.volatileCart = null;
  }

  public get cartId() {
    return this.volatileCart.id;
  }

  public get checkoutId() {
    return this.volatileCart.checkoutId;
  }

  public get totals(): VolatileCartFromServer['cart']['totals'] | undefined {
    return this.volatileCart?.totals;
  }

  public get additionalFees(): VolatileCartFromServer['cart']['additionalFees'] | undefined {
    return this.volatileCart?.additionalFees;
  }
}
